<span [ngStyle]="{
  '--about-me-span-active': active(),
  '--about-me-span-text-color': active() ? 'transparent' : 'inherit'
}"
      [ngClass]="{'italic': detail().italic, 'bg-gradient-to-r from-transparent to-transparent': !active()}"
      [attr.aria-hidden]="!active()"
      class="text-[--about-me-span-text-color]
             opacity-[calc(0.1+var(--about-me-span-active))] transition-[color] duration-700
             ease-out"
>{{ detail().text }}</span>
@if (detail().endOfLine || detail().endOfSection) {
  <span [ngClass]="{'mt-16': detail().endOfSection}" class="block" aria-hidden="true"></span>
}
