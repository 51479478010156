<app-header></app-header>

<app-navigation></app-navigation>

<div class="py-5"></div>

<main>
  <app-about-me></app-about-me>

  <app-book-recommendations
    [id]="navigationEntries.bookRecommendations.id"
  ></app-book-recommendations>

  <app-vita
    [id]="navigationEntries.vita.id"
  ></app-vita>

  <app-favorites
    [id]="navigationEntries.favorites.id"
  ></app-favorites>
</main>

<app-back-to-top-button></app-back-to-top-button>

<ng-container #modalContainer></ng-container>
