<section class="px-5 py-0">
  <div #scrollTrigger [ngStyle]="{
    '--about-me-content-length': contentLength,
    '--about-me-buffer': buffer,
    '--about-me-ppc': ppc,
    '--about-me-pad': pad
  }"
       id="about-me-scroll-trigger"
       class="h-[calc(((var(--about-me-ppc)*var(--about-me-content-length)+(var(--about-me-pad)*var(--about-me-buffer)))*1px)+100vh)]"
  >
    <div
      class="sticky top-0 mx-auto my-0 grid
             min-h-lvh place-items-center font-bold leading-none [font-size:40px] lg:text-7xl"
    >
      <h2 class="hidden">{{ secondSplitText }} {{ thirdSplitText }} {{ fourthSplitText }}</h2>
      <div class="bg-gradient-to-br from-vanilla-extra-dark to-dark-void-dark bg-clip-text
                  text-center dark:from-vanilla-light dark:to-snow">
        @for (detailListEntry of detailList; track detailListEntry) {
          <app-about-me-detail
            [scrollTrigger]="scrollTrigger"
            [detail]="detailListEntry"
            [ppc]="ppc"
          ></app-about-me-detail>
        }
      </div>
    </div>
  </div>
</section>
