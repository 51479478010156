<div class="relative h-8 w-16 rounded-3xl bg-dark-void-dark dark:bg-dark-void">
  <div class="relative inset-0 size-full">
    <label for="theme-mode-toggle-button">
      <input #themeModeCheckbox type="checkbox" id="theme-mode-toggle-button" class="peer hidden"
             (change)="toggleThemeMode()" aria-label="Theme wechseln">
      <div
        class="absolute m-1 size-6 translate-x-0 cursor-pointer rounded-3xl bg-vanilla-dark duration-700 peer-checked:translate-x-7
               peer-checked:bg-transparent peer-checked:shadow-[0.25rem_0_0_0] peer-checked:shadow-vanilla-light"
        aria-hidden="true"></div>
      <span
        class="absolute left-5 top-1.5 z-0 h-0.5 w-5 rounded-[50%] bg-snow transition-all duration-300
               peer-checked:size-0.5" aria-hidden="true"></span>
      <span
        class="absolute left-3 top-3 z-[1] h-0.5 w-6 rounded-[50%] bg-snow transition-all duration-300
               peer-checked:size-1 peer-checked:translate-x-5 peer-checked:transform-gpu"
        aria-hidden="true"></span>
      <span
        class="absolute left-5 top-[1.125rem] z-0 h-0.5 w-5 rounded-[50%] bg-snow transition-all duration-300
               peer-checked:size-0.5 peer-checked:-translate-x-2 peer-checked:transform-gpu"
        aria-hidden="true"></span>
      <span
        class="absolute left-3 top-4 z-0 size-0.5 translate-x-0.5 transform-gpu rounded-[50%] bg-snow opacity-0 transition-all duration-300 peer-checked:translate-x-2.5
               peer-checked:transform-gpu peer-checked:opacity-100 peer-checked:transition-all peer-checked:delay-100 peer-checked:duration-300"
        aria-hidden="true"></span>
      <span
        class="absolute left-7 top-6 z-0 size-1 translate-x-0.5 transform-gpu rounded-[50%] bg-snow opacity-0 transition-all duration-300 peer-checked:-translate-x-1
               peer-checked:transform-gpu peer-checked:opacity-100 peer-checked:transition-all peer-checked:delay-200 peer-checked:duration-700"
        aria-hidden="true"></span>
      <span
        class="absolute left-4 top-5 z-0 size-0.5 translate-x-5 transform-gpu rounded-[50%] bg-snow opacity-0 transition-all duration-300 peer-checked:translate-x-6
               peer-checked:transform-gpu peer-checked:opacity-100 peer-checked:transition-all peer-checked:delay-500 peer-checked:duration-500"
        aria-hidden="true"></span>
    </label>
  </div>
</div>
